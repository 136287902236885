<template>
  <div>
  <div class="siteBackground">
  </div>
    <section class="modalBackground">
      <header><h1 class="title">{{title}}</h1></header>           
      <div class="spinner" v-if="loading">
        <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
    </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['title','loading'],
  emits: ["close"],
  data() {
      return {
          isLoading:true,
          isFullPage: true,
          ladingBackground:"none",
        //   showSpinMessage: false,
        //   loading: false,
        //   spinMessage:"",
      }
  }
};
</script>

<style scoped>
.spinMessage{
    color:white;
    font-size:2em;
    width:100%;
    text-align: center;
}
.siteBackground {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(133, 133, 133, 0.767);
  z-index: 10;
} 
.modalBackground {
  position: fixed;
  top: 35vh;
  left: 15%;
  width: 70%;
  z-index: 100;
  background-color: None;
  
  border: none;  
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.title {
    color: white;
    font-size:3em;
    text-align:center;
    width:100%;
    margin-top:10%;
    z-index:12;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background:rgb(109, 109, 109);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>