<template>
  <div class="background" :style="styleHandler('background')">  
    <spin-modal :title="title" :loading="loading" v-if="showModal" @close="closeModal"></spin-modal>  
    <!-- <transition :name="doTransform ? 'block1' : ''" appear> -->
      <div class="block1"></div>
    <!-- </transition> -->
    <!-- <transition :name="doTransform ? 'headerHeight' : ''" appear> -->
    <div class="header" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
      <div class="block1"></div>
      <div class="frame">
          <div class="fTop"></div>
        <div class="fNav">
          <div
            class="navButtons"
            ref="navButtons"
            :style="styleHandler('navButtons')"
          >
            <div class="navButton" @click="dispatcher('home')" >
              <div :class="selected==='home'? 'navLine':''">Home</div>
            </div>
            <div class="navButton" @click="dispatcher('about')">
              <div :class="selected==='about'? 'navLine':''">About</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
              <div :class="selected==='building'? 'navLine':''">Exteriors</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
              <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
            </div>
            <div class="navButton1" v-if="isMobile">
              <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
              <div class="ddBox" v-if="projectDropDown">
                <div class="ddButton" @click="dispatcher('interiors')">
                  Interiors
                </div>
                <div class="ddLine"></div>
                <div class="ddButton" @click="dispatcher('exteriors')">
                  Exteriors
                </div>
              </div>
            </div>    
            <div class="navButton" >
              <div :class="selected==='contact'? 'navLine':''">Contact</div>
            </div>
          </div>
        </div>
        <div class="fLine" ref="fLine"></div>
        <!-- <transition :name="doTransform? 'mainPage' : ''" appear> -->
        <div class="mainPage">
          <div class="lSide"></div>
          <div class="centerView">
            <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
              <div v-if="!isMobile" style="height: 6%; z-index:1"></div>
              <div class="contactUsBackground">
                <div class="contactInfo" v-if="isMobile">
                  <div class="contactInfo1">
                    <div class="infoTitle" >Contact RBI</div>
                    <div class="infoText">
                      <div class="textrow" ><div class="text1">Phone: </div><div class="text2"><a href="tel:+15745294906" target="_blank">(574) 529-4906</a></div></div><!--:style="styleHandler('infoRow')"-->
                      <div class="textrow" ><div class="text1">Email: </div><div class="text2"><a href="mailto:pnrexroth1@gmail.com">pnrexroth1@gmail.com</a></div></div>
                      <!-- <div class="textrow" :style="styleHandler('infoRow')"><div class="text1">Business Hours: </div><div class="text2">8-5pm Monday-Friday</div></div> -->
                      <div class="textrow" ><div class="text1">Location: </div><div class="text2">Nappannee Indiana</div></div>
                    </div>
                  </div>
                </div>
                <div class="contactForm" ref="contactForm">
                  <p class="formTitle" :style="styleHandler('formTitle')">Send Us A Message</p>
                  <div class="formView">
                  <form @submit.prevent="submitForm">
                    <div class="formNames">
                      <div>
                        <label :style="styleHandler('formLabelFont')">First Name <span class="required">*</span></label><br>                          
                        <input type="text" class="inputBox" name="firstname" :style="styleHandler('formInputHeightFirstName')" v-model="firstname"/>                          
                      </div>
                      <div>
                        <label :style="styleHandler('formLabelFont')">Last Name  <span class="required">*</span></label><br>
                        <input type="text" class="inputBox" name="lastname" :style="styleHandler('formInputHeightLastName')" v-model="lastname"/>                          
                      </div>                        
                    </div>
                    <div v-if="promptFirstName || promptLastName" class="formWarning" :style="styleHandler('warningFont')">please enter your first and last name</div>
                    <label :style="styleHandler('formLabelFont')">Phone (optional)</label>
                    <input type="tel" class="inputBox" :style="styleHandler('formInputHeightPhone')" name="phone" v-model="phone" />
                    <label :style="styleHandler('formLabelFont')">Email <span class="required">*</span></label><br>
                    <input type="email" class="inputBox" name="email" :style="styleHandler('formInputHeightEmail')" v-model="email"/>
                    <div v-if="promptEmail" class="formWarning" :style="styleHandler('warningFont')">please enter your email</div>
                    <label :style="styleHandler('formLabelFont')">Message  <span class="required">*</span></label>
                    <textarea type="text" class="textBox" name="message" :style="styleHandler('formTextAreaHeightMessage')" v-model="message"/>
                    <div v-if="promptMessage" class="formWarning" :style="styleHandler('warningFont')">please enter your message</div>
                    <label :style="styleHandler('formLabelFont')">Additional Details (optional)</label>
                    <textarea type="text" class="textBox" :style="styleHandler('formTextAreaHeightDetails')" name="notes" v-model="notes"/><br>                      
                    <!-- <br v-if="showSuccess || showFailure"> -->
                    <button type="submit" value="Send" :style="styleHandler('formButton')" class="formButton">SEND</button>     
                    <p v-if="showSuccess" class="formResult" :style="styleHandler('formLabelFont')">Your message has been sent!</p>
                    <p v-if="showFailure" class="formResult" :style="styleHandler('formLabelFont')">Sorry - your message failed to send</p>                                                             
                  </form>
                  </div>
                </div>
                <div class="contactInfo" v-if="!isMobile">
                  <div class="contactInfo1">
                    <div class="infoTitle" >Contact RBI</div>
                    <div class="infoText">
                      <div class="textrow" ><div class="text1">Phone: </div><div class="text2"><a href="tel:+15745294906" target="_blank">(574) 529-4906</a></div></div><!--:style="styleHandler('infoRow')"-->
                      <div class="textrow" ><div class="text1">Email: </div><div class="text2"><a href="mailto:pnrexroth1@gmail.com">pnrexroth1@gmail.com</a></div></div>
                      <!-- <div class="textrow" :style="styleHandler('infoRow')"><div class="text1">Business Hours: </div><div class="text2">8-5pm Monday-Friday</div></div> -->
                      <div class="textrow" ><div class="text1">Location: </div><div class="text2">Nappannee Indiana</div></div>
                    </div>
                  </div>
                </div>                  
              </div>
              <div v-if="isMobile" style="height:1px; z-index:1"></div>
            </div>
          </div>
          <div class="rSide"></div>
        </div>
        <!-- </transition> -->
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import SpinModal from '../UI/SpinModal.vue';
export default {
  components: { SpinModal },
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      formTitleFont:null,
      formLabelFont:null,
      formInputHeight: null,
      formTextAreaHeight: null,
      formButtonFont:null,
      warningFont:null,
      formButton:null,
      infoTitle:null,
      infoRow:null,
      infoMargin:null,
      selected: "contact",      
      showModal: false,
      transform: 0  ,
      firstname:'',
      promptFirstName:false,
      lastname:'',
      promptLastName:false,
      phone:'',
      email:'',
      promptEmail:false,
      message:'',
      promptMessage:false,
      notes:'',
      showSuccess:false,
      showFailure:false,
      error:'',
      title: 'Sending Message...',
      loading: true,   
      projectDropDown:false,   
      h1:0,
    };
  },
  computed: {
    textAreaMargin() {
      return !this.promptMessage ? '1em;' : '';
    },

    // h2() {
    //   return window.innerHeight + "px";
    // }
  },
  methods: {
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async submitForm(e){
      console.log(e.target);
      this.promptFirstName = this.firstname === '';
      this.promptLastName = this.firstname === '';
      this.promptEmail = this.email === '';
      this.promptMessage = this.message === '';
      if(!this.promptFirstName && !this.promptLastName && !this.promptEmail && !this.promptMessage){
        this.loading=true;
        this.title="Sending Message...";
        this.showModal=true;
        try {
          console.log(this.message);
          await emailjs.sendForm('service_1lvmrqj','template_sgesd8r', e.target,'3HUgnihPV6xFRGPvI').then((resp) => {
            this.loading=false;
            if(resp){
              this.showSuccess=true;                            
              this.title="Your message has been sent!";
            } else {
              this.error=resp.status;
              this.showFailure=true;
              this.title="Sorry, your message failed to send";
            }          
          });
          await this.timeout(2000);
        } catch(error) {
          console.log(error)
          this.error=error;
          this.showFailure=true;
        }
        this.firstname='',
        this.lastname='',
        this.phone='',
        this.email='',
        this.message='',
        this.notes=''
        this.showModal=false;
      }
    },
    async handleResize() {      
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.5;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;              
    },
    async handleResize1() {
      this.navFontHeight = this.$refs.navButtons.clientHeight * 0.49;
      this.formTitleFont = this.$refs.contactForm.clientHeight * .05;
      this.formLabelFont = this.$refs.contactForm.clientHeight * .03;
      this.formInputHeight = this.$refs.contactForm.clientHeight * .04;
      this.formTextAreaHeight = this.$refs.contactForm.clientHeight * .1;
      this.warningFont = this.$refs.contactForm.clientHeight * .02;
      this.formButton = this.$refs.contactForm.clientHeight * .08;
      this.infoTitle=this.$refs.contactForm.clientHeight*.1;
      this.infoRow = this.$refs.contactForm.clientHeight*.05;
      this.infoMargin= this.$refs.contactForm.clientHeight*.05;
    },
    handleResize2() {
      this.h1 = window.innerHeight - this.$refs.fLine.getBoundingClientRect().bottom +"px";
      console.log("Contats: HR2");
      this.h2 = window.innerHeight + "px";
    },
    windowSize(){
        // console.log(window.innerWidth);
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    async resizeHandler(){
      if (this.$refs.hTitle) {
        await this.handleResize();
        await this.handleResize1();
        this.handleResize2();
      }
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name === "navButtons") {
        return { "font-size": this.navFontHeight + "px" };
      }else if(name==="formTitle"){
        return { "font-size": this.formTitleFont + "px" };
      } else if(name==="formLabelFont"){
        return { "font-size": this.formLabelFont + "px",
          "margin-bottom": this.textAreaMargin }
      } else if(name==="formInputHeightFirstName") {
        return {"height": this.formInputHeight + "px",
          "margin-bottom": !this.promptFirstName ? '1em' : ''
        }
      } else if(name==="formInputHeightLastName") {
        return {"height": this.formInputHeight + "px",
          "margin-bottom": !this.promptLastName ? '1em' : ''
        }
      }
      else if(name==="formInputHeightEmail") {
        return {"height": this.formInputHeight + "px",
          "margin-bottom": !this.promptEmail ? '1em' : ''
        }
      }else if(name==="formInputHeightPhone") {
        return {"height": this.formInputHeight + "px",
          "margin-bottom": '1em'
        }
      }else if(name==="formTextAreaHeightMessage"){
        return { "height": this.formTextAreaHeight + "px",
          "margin-bottom": !this.promptMessage ? '1em' : '0'
        }
      }else if(name==="formTextAreaHeightDetails"){
        return {"height": this.formTextAreaHeight + "px",
          "margin-bottom": '1em'
        }
      } else if(name==="warningFont"){
        return {"font-size": this.warningFont + "px"}
      } else if(name==="formButton"){
        return {"height": this.formButton + "px",
          "margin-bottom": !this.showSuccess && !this.showFailure?'1em;' : '0'
        }
      } else if(name==="infoTitle"){
        return {"font-size": this.infoTitle + "px" }
      } else if(name==="infoRow"){
        return {"font-size": this.infoRow + "px",
          'margin-bottom': this.infoMargin + "px" }
      }
      // else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // }
    },
    projectClick(){
      this.selected='projects';
      this.projectDropDown=true;
    },
    dispatcher(name) {
      this.projectDropDown=false;
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      setTimeout( () => this.$router.push({path: '/'+name}),500);
    },
    orChange(){
      console.log("Orientation Change");
      this.resizeHandler();
    }
  },
  mounted() {
    this.resizeHandler();
    window.addEventListener('orientationchange', this.orChange);
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
  },
  created() {
    this.transform=1;
  },
  closeModal(){
    this.showModal=false;
  }
};
</script>
<style scoped>
@media only screen and (max-width: 900px) {

}
/* Form */
.formWarning {  
  text-align:center;
  color:red;
  /* margin-bottom:2em; */
  /* font-size:80%; */
}
.required {
  color:red;
  vertical-align:sub;
}
.formResult {
  text-align: center;
  color:green;
}
.contactUsBackground {
  background-color:rgb(221, 210, 178);
  padding:2%;
  margin-right:6em;
  margin-left:6em;  
  display:flex;  
  font-family: Arial, Helvetica, sans-serif;
}
.infoTitle {
  font-size:4vh;
  color: rgb(109, 109, 109);
  /* text-align:center; */
  margin-bottom:3vh;
  /* text-align:center; */
}
a {
  color:white;
}
.infoText {
  color:white;
  font-size:2vh;
}
.text1{
  color:rgb(109, 109, 109);
  /* margin-bottom:30px; */
  margin-bottom:5%;
  margin-right:10px;
}
.textrow {
  display:flex;
}
.contactInfo {  
  height:100%;
  width:50%;
  /* margin-left:5%; */
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-left:5%;
}
.contactInfo1 {
  height:100%;  
  display:flex;
  flex-direction:column;
  justify-content:center;
  /* align-items:center;   */
}
.formTitle {
  /* font-size:30%; */
  padding:0;
  margin:0;
  width:100%;
  /* font-size:4vh; */
  text-align:center;
  white-space:nowrap;
  margin-bottom:7vh;
}
.contactForm {
  background-color: white;  
  width:50%;
  /* font-size:1.2em; */
  /* font-size:1.8vh; */
  padding: 2.5vh;
  font-family: Arial, Helvetica, sans-serif;  
}
.formView {
  /* height:100%;
  display:flex;
  align-items:center; */
  width:100%;
  margin:0;
  padding:0;
}
.formNames {
  display:flex;
  justify-content: space-between;
}
.formNames input {
  width:100%;  
}
.formNames > div {
  width:45%;  
}
form {
  /* width:100%;   */
}

input {
  /* height:2em; */
  /* height:2.5vh; */
  /* margin-bottom:1em; */
  width:100%;
  font-family: Arial, Helvetica, sans-serif;   
  box-sizing: border-box; 
  /* font-size:.8em; */
}

.textBox {
  /* height:8vh; */
  width:100%;
  font-family: Arial, Helvetica, sans-serif;   
  box-sizing: border-box;
  /* font-size:.8em; */
}
.formButton {
  width: 60%;
  margin-left:20%;
  height:2em;
  font-size:1.5em;
  background-color:rgb(221, 210, 178);
  border: 2px solid rgb(129, 129, 129);
  color: rgb(109, 109, 109);
}
.formButton:hover {
  cursor:pointer;
}
.navLine {
  border-bottom:2px solid black;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
  /* transform: translateY(-14%); */
}

/* Header */
.header {
  display: flex;
  height: 0;  
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 104%;  
  height: 85%;
  transform: translateX(-2%);
  /* overflow-y:auto; */
  /* overflow-x:auto; */
  /* overflow-y:hidden; */
}

.fTop {
  width: 100%;
  height: 1%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 109%;
  overflow-y:hidden;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
  /* overflow-y:auto !important; */
  overflow-y:hidden;
  display:flex;
  align-items:center;
  /* min-width:70em;
  min-height:60em; */
  /* margin-bottom:10px; */
}
.phrase1 {
  width: 80%;
  margin-left: 10%;
  /* background-color: rgb(221, 210, 178); */

  color: white;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.8em;
  margin-bottom:5%;
}



.centerView1{
  width:100%;
  height:100%;
}
.centerViewIn {
  animation: centerViewIn .5s forwards;
}
.centerViewOut {
  animation: centerViewOut .5s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
@media only screen and (max-width: 900px) {
  .mainPage{
    height:v-bind(h1) !important;
  }
  .background {
    height: v-bind(h2) !important;
    overflow-y:hidden !important;
  }
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
    z-index:100;
  }
  .fNav {
    height:5.95vh;
  }
  .fLine, .fTop {
    height:1vh;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
    z-index:100;
  }
  
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:100;
  }
  .ddBox {
    position:fixed;
    z-index:100;
    background-color:white;    
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;

  }
  .contactUsBackground {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height:100%; */
    margin:1em;    
    padding-bottom:1em;
    padding-top:1em;
    /* height:fit-content !important; */
    /* margin-bottom:4em !important; */
  }
  .contactForm {
    width:85%;    
    /* margin-bottom:10%; */    
  }
  .contactInfo {
    width:80%;
    height:100%;
    margin:0;
    padding:0;
    text-align:center;
    margin-bottom:1em;
  }
  .contactInfo1 {
    width:100%;
    height:100%;    
  }
  .infoTitle {
    width:100%;
    text-align:center;
  }
  .infoText {
    font-size:1em;
  }
  .centerView{  
    overflow-y:auto;      
  }
}
</style>