<template>
  <!-- <div v-if="true" class="background" :style="styleHandler('background')"> -->
  <div v-if="true" class="background">
    <div class="block1" :class="transform===1 ? 'block1In' : transform===2?'block1Out':''"></div>    
    <div class="header" :class="transform===1 ? 'headerIn' : transform===2 ? 'headerOut' : ''" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
    <div class="block1"  :class="transform===1 ? 'block1In' : transform===2?'block1Out':''"></div>
    
    <div class="frame" :class="transform===1?'frameIn' : transform===2 ? 'frameOut':''">   
      <!-- <div class="frameTop"> -->
        <div class="fTop" :class="transform===1?'fTopIn' : transform===2? ' fTopOut':''"></div>    
        <div class="fNav" :class="transform===1?'fNavIn' : transform===2?'fNavOut':''">
          <div class="navButtons" ref="navButtons" :style="styleHandler('navButtons')">
            <div class="navButton" >
                <div :class="selected==='home'? 'navLine':''">Home</div>
              </div>
              <div class="navButton" @click="dispatcher('about')">
                <div :class="selected==='about'? 'navLine':''">About</div>
              </div>
              <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
                <div :class="selected==='building'? 'navLine':''">Exteriors</div>
              </div>
              <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
                <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
              </div>
              <div class="navButton1" v-if="isMobile" ref="navButton1">
                <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
                <div class="ddBox" v-if="projectDropDown">
                  <div class="ddButton" @click="dispatcher('interiors')">
                    Interiors
                  </div>
                  <div class="ddLine"></div>
                  <div class="ddButton" @click="dispatcher('exteriors')">
                    Exteriors
                  </div>
                </div>
              </div>            
              <div class="navButton" @click="dispatcher('contact')">
                <div :class="selected==='contact'? 'navLine':''">Contact</div>
              </div>
          </div>
        </div>
        <div class="fLine" ref="fLine"></div>    
      <!-- </div>  -->
      <div class="mainPage" :class="transform===1?'mainPageIn' : transform===2?'mainPageOut':''">
        <div class="lSide"></div>
        <div class="centerView" ref="cv" >
          <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
            <!-- <div style="height:6%;"></div> -->
            <div class="phrase1" ref="phrase1" :style="styleHandler('phrase1')">Creating Inspiring Spaces</div>
            <!-- <div style="height:6%;"></div> -->
            <img src="~@/assets/oremodel.jpg" class="image" />
            <!-- <div style="height:6%;"></div> -->
            <div class="phrase2" ref="phrase2" :style="styleHandler('phrase2')">
              Staircases&nbsp;&#x2022;&nbsp;Trim&nbsp;&#x2022;&nbsp;Built-ins&nbsp;&#x2022;&nbsp;Remodels&nbsp;&#x2022;&nbsp;Additions&nbsp;
            </div>
            <div v-if="isMobile" style="height:6vh"></div>
          </div>            
        </div>
        <div class="rSide"></div>
      </div>            
    </div>    
  </div>
  <!-- <div v-else>
    <div class="mBackground">
      <div class="mTop">
      </div>
      <div class="mNav">
        <div class="menuButton">MENU</div>
      </div>
      <div class="mLine">
      </div>
      <div class="mCenterView">
        <div class="mLeft"></div>
        <div class="mCenter">
        </div>
        <div class="mRight"></div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      headerWatcher: null,         
      transform: 0,
      selected:"home", 
      isMobile:false,
      projectDropDown:false,
      h1: 0,
      h2: 0,
      h3: 0,
    };
  },
  methods: {
    handleResize() {
      if (this.$refs.hTitle) {
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.50;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;
        this.navFontHeight = this.$refs.navButtons.clientHeight *0.49;
        if(this.isMobile){
          this.phrase1Height = this.$refs.phrase1.clientHeight*.6;
        } else {
          this.phrase1Height = this.$refs.phrase1.clientHeight*.8;
        }
        this.phrase2Height = this.$refs.phrase2.clientHeight;                                        
      }
      this.h1 = this.$refs.cv.clientHeight + "px";
      this.h2 = this.$refs.cv.getBoundingClientRect().top;
      this.h3 = this.$refs.fLine.getBoundingClientRect().top + "px";
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name==="navButtons"){
        return { "font-size": this.navFontHeight + "px"};
      } 
      //  else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // } else if (name === "phrase2"){
      //   return { "font-size": this.phrase2Height + "px" }
      // }
    },
    projectClick(){
      console.log("Project Click");
      this.selected='projects';
      this.projectDropDown=true;
    },
    dispatcher(name) {
      console.log(name);
      this.projectDropDown=false;
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      setTimeout( () => this.$router.push({path: '/'+name}),1000);
    },
    windowSize(){
        // console.log(window.innerWidth);
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    orChange(){
      console.log("Orientation Change");
      this.$forceUpdate();
    }
  },
  onBeforeRouteLeave(to, from, next) {
    this.doTransform=true;
    setTimeout(() => {
      next();
      }, 2000
    );    
  },
  mounted() {
    // window.addEventListener('orientationchange', this.orChange);
    window.addEventListener('load',this.handleResize);
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
    window.addEventListener("resize", this.handleResize);
    this.headerWatcher = new ResizeObserver(this.handleResize).observe(this.$refs.header);
  },
  created() {
    // this.$nextTick(() => {
    //   this.handleResize();
    // });
    var route = this.$store.getters.previousRoute;          
    if(route && route.fullPath && route.fullPath != '/home' && route.fullPath != '/'){        
        this.doTransform= true;
        this.transform=1;
    } else {
      this.doTransform=false;
      this.transform=0;
    }
    console.log('Transform: ' + this.doTransform);
    console.log(window.innerHeight);
  },
    
  computed: {
    h4() {
      console.log("h4: " + window.innerHeight);
      return window.innerHeight;

    }
  }
};
</script>
<style scoped>

.navLine {
  border-bottom:2px solid black;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;  
  overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
}
.block1 {
  height: .5%;
}
/* Header */
.header {
  display: flex;
  /* height: 14%; */
  height:14%;
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 80%;
  height: 85%;
  margin-left: 10%;
  overflow-y:none;
}
.fTop {
  width: 100%;
  height: 3%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top:1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;  
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton, .navButton1 {
  display:flex;
  height:100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor:pointer;
  background-color:rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor:pointer;
  background-color:rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 89%;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
}
.centerView1{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.phrase1 {
  width: 100%;     
  /* height:12%;    */
  font-size:3.5em;
  color: white;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
}
.image {
  height: 55%;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;    
  border: 6px solid rgb(221, 210, 178);
}
.phrase2 {  
  width:100%;
  /* height:5%;     */
  font-size:2em;
  text-align:center;    
  color:white;
}
.headerIn {
  height:0;
  animation: headerIn 1s forwards;
}
.headerOut {
  height:14%;
  animation: headerOut 1s forwards;
}
@keyframes headerIn {
  from { height:0; }
  to { height: 14%; }
}
@keyframes headerOut {
  from { height:14%; }
  to {height: 0; }
}

.block1 {
  height:.5%;
}
.block1In {
  /* height:0; */
  animation: block1In 1s forwards;
}
.block1Out {
  /* height:.5%; */
  animation: block1Out 1s forwards;
}
@keyframes block1In {
  from { height:0; }
  to { height: .5%; }
}
@keyframes block1Out {
  from { height:.5%; }
  to {height: 0; }
}

.frameIn {
  animation: frameIn 1s forwards;
}
.frameOut {
  animation: frameOut 1s forwards;
}
@keyframes frameIn {
  from { width: 104%; margin-left:0; transform: translateX(-2%) }
  to { width:80%; margin-left:10%; }
}
@keyframes frameOut {
  from { width:80%; margin-left:10%; }  
  to { width: 104%; margin-left:0; transform: translateX(-2%) }
}

.fTopIn {
  animation: fTopIn 1s forwards;
}
.fTopOut {
  animation: fTopOut 1s forwards;
}
@keyframes fTopIn {
  from { height:1%; }
  to { height:3%; }
}
@keyframes fTopOut {
  from { height:3%; }  
  to { height:1%; }
}
.mainPageIn {
  animation: mainPageIn 1s forwards;
}
.mainPageOut {
  animation: mainPageOut 1s forwards;
}
@keyframes mainPageIn {
  from { height:109%; }
  to { height:89%; }
}
@keyframes mainPageOut {
  from { height:89%; }  
  to { height:109%; }
}
.centerViewIn {
  animation: centerViewIn 1s forwards;
}
.centerViewOut {
  animation: centerViewOut 1s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
/*****   MOBILE SCREEN   *****/
@media only screen and (max-width: 900px) {    
  .background{
    overflow-y: scroll !important; 
    /* height: v-bind(h4)+'px' !important; */
    /* height:100px !important;   */
    /* background-color:green; */
  }
  .header {
    height:14vh;
  }
  @keyframes headerIn {
    from { height:0; }
    to { height: 14vh; }
  }
  @keyframes headerOut {
    from { height:14vh; }
    to {height: 0; }
  }

  .frame {
    width:96%;
    margin-left:2%;
  }
  .fTop {
    height:2.55vh;
  }
  @keyframes fTopIn {
    from { height:1vh; }
    to { height:2.55vh; }
  }
  @keyframes fTopOut {
    from { height:2.55vh; }  
    to { height:1vh; }
  }
  .fNav {
    height:5.95vh;
  }
  .fLine {
    height:.85vh;
  }
  @keyframes frameIn {
    from { width: 104%; margin-left:0; transform: translateX(-2%) }
    to { width:96%; margin-left:2%; }
  }
  @keyframes frameOut {
    from { width:96%; margin-left:2%; }  
    to { width: 104%; margin-left:0; transform: translateX(-2%) }
  }
  @keyframes centerViewIn {
    from { opacity:0; min-height:92.65vh; }
    to { opacity:1; min-height:76.65vh; }
  }
  @keyframes centerViewOut {
    from { opacity:1; min-height:76.65vh;}  
    to { opacity:0; min-height:92.65vh; }
  }
  .fNav {
    width:94%;
    margin-left:3%;
  }
  .mainPage {
    width:94%;
    margin-left:3%;
    z-index:1;          
  }
  /* @keyframes mainPageIn {
    from { height:109%; width:97%; margin-left:1.5%;  }
    to { height:89%; width:94%; margin-left:3%;}
  }
  @keyframes mainPageOut {
    from { height:89%; width:94%; margin-left:3%; }  
    to { height:109%; width:97%; margin-left:1.5%; }
  } */
  @keyframes mainPageIn {
    from { width:97%; margin-left:1.5%;  }
    to { width:94%; margin-left:3%;}
  }
  @keyframes mainPageOut {
    from { width:94%; margin-left:3%; }  
    to { width:97%; margin-left:1.5%; }
  }

    /* height:fit-content !important;    */
    /* min-height:100%; */
    /* min-height:76.65vh; */
  .centerView {    
    height:fit-content !important;
    min-height:76.65vh;
  }
  .centerView1 {
    /* min-height:v-bind(h1);     */
    min-height:76.65vh;
  }
  .centerView, .centerView1 {
    z-index:1;
  }
  /* @keyframes mainPageIn {
  from { height:109%; width:97%; margin-left:1.5%; }
  to { height:94%; }
}
@keyframes mainPageOut {
  from { height:94%; }  
  to { height:109%; width:97%; margin-left:1.5%;}
} */
.fNavIn {
  animation: fNavIn 1s forwards;
}
.fNavOut {
  animation: fNavOut 1s forwards;
}
@keyframes fNavIn {
  from { width: 97%; margin-left:1.5%; }
  to { width:94%; margin-left:3%; }
}
@keyframes fNavOut {
  from { width:94%; margin-left:3%; }
  to { width: 97%; margin-left:1.5%; }
}
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
    z-index:100;
  }
  .navButton1 {
    position:relative;
    box-sizing:border-box;
    padding:0;
    margin:0;
  }
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:100;
    box-sizing:border-box;
  }
  .ddBox {    
    position:fixed;        
    transform: translateY(5.95vh);
    /* box-sizing:border-box; */
    /* padding:0; */
    /* margin:0; */
    /* margin-top:22%;     */
    /* top: v-bind(h3); */
    background-color:white;    
    z-index:100;
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;
    box-sizing:border-box;

  }
  .image {
    width:90%;
    height:auto;
  }
  .phrase1{
    font-size:2em;
    margin-top:15px;
    margin-bottom:15px;
  }
  .phrase2 {
    font-size: .7em;   
    margin-top:15px;
    margin-bottom:15px; 
  }
  .centerView1 {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .rSide, .lSide {
    height: v-bind(h1);
  }
}
</style>