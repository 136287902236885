<template>
    <div class="background-image">
        <slot></slot>
    </div>
</template>

<script>
export default{

}
</script>

<style scoped>
.background-image {    
    position: fixed;
    background-size:cover;
    background-image: url('~@/assets/background/b2-1.jpg');  
    top:0;
    left:0;
    height:100vh;    
    width:100vw;
    z-index:10;        
    /* text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16), 0 4px 6px rgba(129, 129, 129, 0.45); */
    /* , 0 7px 10px rgba(156, 156, 156, 0.45)      */
}

</style>
