import { createApp } from 'vue';
import BaseBackground from './components/UI/BaseBackground.vue';
import store from './store/index.js';
import vClickOutside from 'v-click-outside'

import router from './router.js';
import App from './App.vue';
const app = createApp(App);
app.component('base-background', BaseBackground);
app.use(router);
app.use(store);
app.use(vClickOutside);
app.mount('#app');