<template>
  <div class="background" :style="styleHandler('background')">    
    <!-- <transition :name="doTransform ? 'block1' : ''" appear> -->
      <div class="block1"></div>
    <!-- </transition> -->
    <!-- <transition :name="doTransform ? 'headerHeight' : ''" appear> -->
    <div class="header" ref="header">
      <div class="hCenter">
        <div class="hTitle" ref="hTitle" :style="styleHandler('hTitle')">
          Rexroth
        </div>
        <div class="hLine"></div>
        <div
          class="hSubTitle"
          ref="hSubTitle"
          :style="styleHandler('hSubTitle')"
        >
          Building & Interiors LLC
        </div>
      </div>
    </div>
      <div class="block1"></div>
      <div class="frame">
          <div class="fTop"></div>
        <div class="fNav">
          <div
            class="navButtons"
            ref="navButtons"
            :style="styleHandler('navButtons')"
          >
            <div class="navButton" @click="dispatcher('home')" >
              <div :class="selected==='home'? 'navLine':''">Home</div>
            </div>
            <div class="navButton" @click="dispatcher('about')">
              <div :class="selected==='about'? 'navLine':''">About</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('exteriors')">
              <div :class="selected==='building'? 'navLine':''">Exteriors</div>
            </div>
            <div class="navButton" v-if="!isMobile" @click="dispatcher('interiors')">
              <div :class="selected==='interiors'? 'navLine':''">Interiors</div>
            </div>
            <div class="navButton1" v-if="isMobile">
              <div @click="projectClick" :class="selected==='projects'? 'navLine':''">Projects</div>
              <div class="ddBox" v-if="projectDropDown">
                <div class="ddButton" @click="dispatcher('interiors')">
                  Interiors
                </div>
                <div class="ddLine"></div>
                <div class="ddButton" @click="dispatcher('exteriors')">
                  Exteriors
                </div>
              </div>
            </div>    
            <div class="navButton" @click="dispatcher('contact')">
              <div :class="selected==='contact'? 'navLine':''">Contact</div>
            </div>
          </div>
        </div>
        <div class="fLine"></div>
        <!-- <transition :name="doTransform? 'mainPage' : ''" appear> -->
        <div class="mainPage">
          <div class="lSide"></div>
          <div class="centerView">
            <div class="centerView1" :class="transform===1?'centerViewIn' : transform===2?'centerViewOut':''">
              <div v-if="selectedFolder===-1">
              <div class="folderRow" v-for="n in folIndex" :key="n">            
                <div class="folderBlock">                  
                  <div class="folderBlock1" @click="selectFolder((n-1)*3)">
                    <p class="folTitle">{{folders[(n-1)*3]}}</p>
                    <img class="folImg" :src="require('../../assets/InteriorsOptimized' + getImagePath([(n-1)*3][0]))" />                    
                  </div>
                </div>
                <div class="folderBlock">   
                  <div class="folderBlock1" @click="selectFolder(((n-1)*3)+1)">
                    <p class="folTitle">{{folders[((n-1)*3)+1]}}</p>
                    <img class="folImg" :src="require('../../assets/InteriorsOptimized' + getImagePath([((n-1)*3)+1][0]))" />
                    </div>                  
                </div>
                <div class="folderBlock">                  
                  <div class="folderBlock1" @click="selectFolder(((n-1)*3)+2)">
                    <p class="folTitle">{{folders[((n-1)*3)+2]}}</p>
                    <img class="folImg" :src="require('../../assets/InteriorsOptimized' + getImagePath([((n-1)*3)+2][0]))" />
                  </div>                    
                </div>
              </div>              
            </div>
              <div v-else-if="selectedPhoto===-1">                
                <!-- <div style="height:80px"></div> -->
                <div class="photoRow" v-for="n in imageIndex(selectedFolder)" :key="n">   
                  <div class="folderBlock">
                    <img class="photoImg"  @click="photoSelected((n-1)*3)" :src="require('../../assets/InteriorsOptimized' + getImagePath1(selectedFolder,(n-1)*3))" />
                  </div>         
                  <div class="folderBlock">
                    <img v-if="((n-1)*3)+1 < imageCount(selectedFolder)" @click="photoSelected(((n-1)*3)+1)" class="photoImg" :src="require('../../assets/InteriorsOptimized' + getImagePath1(selectedFolder,((n-1)*3)+1))" />
                  </div>                    
                  <div class="folderBlock">
                    <img v-if="((n-1)*3)+2 < imageCount(selectedFolder)" @click="photoSelected(((n-1)*3)+2)" class="photoImg" :src="require('../../assets/InteriorsOptimized' + getImagePath1(selectedFolder,((n-1)*3)+2))" />
                  </div>
                </div>
                <button class="backBtn" @click="selectBack">Back</button>
              </div>
              <div v-else>
                <div class="liDiv">
                    <img class="largeImage" v-click-outside="exitClick" :src="require('../../assets/InteriorsOptimized' + getImagePath1(selectedFolder, selectedPhoto))" />
                    <button class="leftButton" @click="leftClick">&lt;</button>
                    <button class="rightButton" @click="rightClick">></button>
                    <button class="exitButton" @click="exitClick">x</button>
                    <!-- <div class="imgBack" @click="console.log('test')"></div> -->                    
                  </div>
              </div>
            </div>
          </div>
          <div class="rSide"></div>
        </div>
        <!-- </transition> -->
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
// import * as fs from 'fs';
export default {
  data() {
    return {
      hTitleFont: null,
      hSubTitleFont: null,
      navFontHeight: null,
      phrase1Height: null,
      phrase2Height: null,
      minHeight: null,
      minWidth: null,
      selected: "interiors",
      aboutText:
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think." +
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think." +
        "Carpentry is a worldwide occupation that includes building and repairing things made from wood and other materials. A carpenter probably built the house that you live in and the building that you work in. Carpenters are located all around the world in places you would never think.",
      transform: 0,
      images: [],
      folders: [],
      imgLength:0,
      imgIndex:0,
      folLength:0,
      folIndex:0,
      selectedFolder:-1,
      selectedPhoto:-1,
      projectDropDown:false,
      h2:0,
    };
  },
  methods: {
    async handleResize() {
      if (this.$refs.hTitle) {
        this.hTitleFont = this.$refs.hTitle.clientHeight * 0.7;
        this.hSubTitleFont = this.$refs.hSubTitle.clientHeight * 0.5;
        this.minWidth = screen.width * 0.65;
        this.minHeight = screen.height * 0.65;
      }
    },
    handleResize1() {
      this.navFontHeight = this.$refs.navButtons.clientHeight * 0.49;
      this.h2 = window.innerHeight + "px";
    },
    async resizeHandler(){
      await this.handleResize();
      this.handleResize1();
    },
    styleHandler(name) {
      if (name === "background") {
        return {
          "min-height": this.minHeight + "px",
          "min-width": this.minWidth + "px",
        };
      } else if (name === "hTitle") {
        return { "font-size": this.hTitleFont + "px" };
      } else if (name === "hSubTitle") {
        return { "font-size": this.hSubTitleFont + "px" };
      } else if (name === "navButtons") {
        return { "font-size": this.navFontHeight + "px" };
      }
      // else if (name === "phrase1"){
      //   return { "font-size": this.phrase1Height + "px" }
      // }
    },
    resetPage(){
      this.transform=1;
      this.selectedFolder=-1;
      this.selectedPhoto=-1;
    },
    windowSize(){
        // console.log(window.innerWidth);
        if(window.innerWidth<=900){
            this.isMobile=true;
        } else {
            this.isMobile=false;
        }
        console.log(this.isMobile);
    },
    projectClick(){
      console.log("Clicked");
      this.selected='projects';
      this.projectDropDown=true;
    },
    dispatcher(name) {
      this.projectDropDown=false;      
      window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
      });
      this.selected=name;
      this.transform=2;
      
      if(name==='interiors'){        
        setTimeout(() => this.resetPage(),500);
      } else {
        setTimeout( () => this.$router.push({path: '/'+name}),500);
      }
    },
    importAll(r) {
      // function compare(a, b) {
      //   if (a.name < b.name)
      //     return -1;
      //   if (a.name > b.name)
      //     return 1;
      //   return 0;
      // }
      function sortAlphaNum(a,b) {
        return a.localeCompare(b, 'en', {numeric: true});
      }
      var imgs = []        
      var folder=""
      // var name=""
      var keys = r.keys();
      keys = keys.sort(sortAlphaNum);
      console.log(keys);
      keys.forEach(key=>(
          folder = key.substring(2),
          folder = folder.substring(0,folder.indexOf('/')),           
          imgs.push(folder)          
        ));      
      this.folders = [...new Set(imgs)];
      for(var i = 0; i < this.folders.length; i++){
        this.images.push(new Array());
      }
      keys.forEach((key)=>(
        folder = key.substring(2),
        folder = folder.substring(0,folder.indexOf('/')),   
        this.images[this.folders.indexOf(folder)].push(key)
      ))
      console.log(this.images);
    },
    getImagePath(index){
      return this.images[index][0].substring(1);
    },
    getImagePath1(index,photo){
      return this.images[index][photo].substring(1);
    },
    selectFolder(index){
      this.selectedFolder=index;    
    },
    imageCount(index){
      return this.images[index].length;
    },
    imageIndex(index){
      return Math.ceil(this.images[index].length/3)
    },
    selectBack(){
      this.selectedFolder=-1;
    },
    photoSelected(index){      
      this.selectedPhoto=index;
    },
    leftClick(){
      if(this.selectedPhoto===0){
        this.selectedPhoto=Object.keys((this.images[this.selectedFolder])).length-1;
      } else {
        this.selectedPhoto = this.selectedPhoto - 1;
      }
    },
    rightClick(){
      if(this.selectedPhoto===Object.keys((this.images[this.selectedFolder])).length-1){
        this.selectedPhoto=0;
      } else {
        this.selectedPhoto = this.selectedPhoto + 1;
      }
    },
    exitClick(){
      if(this.selectedPhoto != -1){
        this.selectedPhoto=-1;
      }
    },
    orChange(){
      console.log("Orientation Change");
      this.resizeHandler();
    }
  },
  mounted() {
    this.resizeHandler();
    window.addEventListener('orientationchange', this.orChange);
    window.addEventListener("resize", this.resizeHandler);    
    this.importAll(require.context("../../assets/InteriorsOptimized/", true, /\.jpg$/));
    // const fs = require('fs');
    // fs.readdir('../../assets/InteriorsOptimized', (err, data) =>{
    //   console.log(data);
    // });
    this.folIndex = Math.ceil(Object.keys(this.folders).length/3)
    this.folLength = Object.keys(this.folders).length;
    this.imgIndex = Math.ceil(Object.keys(this.images).length/3)
    this.imgLength = Object.keys(this.images).length;
    // console.log(this.this.imgIndex);
    console.log(this.folLength);
    console.log(this.folIndex);
    window.addEventListener('resize',this.windowSize);            
    this.windowSize();       
    
  },
  created() {
    this.transform=1;
    window.addEventListener('keydown', (e) => {
      if(this.selectedPhoto!=-1){
        if(e.key=='ArrowRight') {
          this.rightClick();
        }
        else if(e.key==='ArrowLeft'){
          this.leftClick();
        } else if(e.key==='Escape'){
          this.exitClick();
        }
      }
    })
  },
};
</script>
<style scoped>
.navLine {
  border-bottom:2px solid black;
}
.background {
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(221, 210, 178);
  margin: 0;
  padding: 0;
    overflow-x: hidden;
  overflow-y:hidden;
  box-sizing: border-box;
}
.leftButton,.rightButton {
  font-size:2em;
  color:black;
  position:absolute;  
  padding:2%;
  padding-right:1.5%;
  padding-left:1.5%;
  text-align:center;
  border-radius: 8%;  
  border:none;
  opacity:.5;
  z-index:100;
}
.rightButton {
  right:12%;
}
.leftButton {
  left:12%;
}
.leftButton:hover, .rightButton:hover {
  opacity:1;
  cursor:pointer;
}
.exitButton {
  font-size:2em;
  color:black;
  background-color:rgba(255, 255, 255, 0.688);  
  position:absolute;
  /* padding:1.5%; */
  /* padding-right:2.5%; */
  /* padding-left:2.5%; */
  width:5vw;
  height:5vw;
  text-align:center;
  border-radius:50%;
  border:none;
  opacity:.5;
  top:20%;
  z-index:100;
}
.exitButton:hover {
  opacity:1;
  cursor:pointer;
}
.liDiv {
  width:100%;  
  display:flex;
  justify-content: center;
  align-items: center;
  
}
.largeImage {
  max-width:95%;
  max-height:80vh;
  margin-top:5%;
  border:4px solid rgb(173, 173, 173);
  box-sizing: border-box;
}
.backBtn {
  color:black;
  font-size: 1.5em;
  margin:2%;
  position:absolute;
  top:10vh;
  right:20vh;
  padding:.5%;
  background-color:rgba(255, 255, 255, 0.688);  
  border:none;  
  border-radius:12%;
}
.backBtn:hover {
  cursor:pointer;
  background-color:white;
}
.folderRow {
  display:flex;      
  /* height:40%;   */
  justify-content: space-evenly;  
  padding-top:1.8%;
  padding-bottom:1.8%;
}
.folderBlock {    
  width:29%;  
  text-align:center;  
}
.folTitle{  
  color:white;
  padding-top:12px;
  padding-bottom:12px;    
  margin:0;
  font-size:1.5em;
}
.folImg { 
  width:96%;   
  margin-left:2%;    
  margin-right:2%;
  margin-bottom:1%;
}
.photoRow {
  display:flex;      
  /* height:40%;   */
  justify-content: space-evenly;  
  padding-top:1.8%;
  padding-bottom:1.8%;
}
.photoImg {  
  border:4px solid rgb(173, 173, 173);
  width:100%;
  box-sizing:border-box;
}
.photoImg:hover {
  cursor:pointer;
  filter:brightness(.8);
}
/* .folTitle:hover, .folImg:hover{
  opacity:.5;
} */
.folderBlock1 {
  background-color: rgb(99, 99, 99); 
  margin:0;
  padding:0;  
}
.folderBlock1:hover {
  cursor:pointer;
  background-color:rgb(173, 173, 173) !important;
}

/* Header */
.header {
  display: flex;
  height: 0;  
}
.hCenter {
  justify-content: center;
  height: 100%;
  margin: auto;
  padding: 0;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  text-shadow: 0 1px 2px rgba(65, 65, 65, 0.16),
    0 4px 6px rgba(129, 129, 129, 0.45);
}
.hTitle {
  height: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
}
.hLine {
  height: 1.5%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.hSubTitle {
  height: 46%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: rgb(170, 0, 0);
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

/**** Frame ****/
.frame {
  width: 104%;
  height: 85%;
  transform: translateX(-2%);
}
.fTop {
  width: 100%;
  height: 1%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  box-sizing: border-box;
}
.fNav {
  width: 97%;
  height: 7%;
  margin-left: 1.5%;
  background-color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  box-sizing: border-box;
  box-shadow: inset 0px 6px 4px -4px #888;
}
.navButtons {
  display: flex;
  height: 100%;
  width:80%;
  margin-left:10%;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  font-family: sans-serif;
}
.navButton {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:auto;
}

.navButton:hover {
  cursor: pointer;
  background-color: rgba(189, 189, 189, 0.253);
}
.navButton:active {
  cursor: pointer;
  background-color: rgba(156, 156, 156, 0.253);
}
.fLine {
  width: 98.2%;
  height: 1%;
  margin-left: 0.9%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.mainPage {
  width: 97%;
  margin-left: 1.5%;
  height: 109%;
  overflow-y:none;
}
.lSide {
  width: 5%;
  float: left;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.rSide {
  width: 5%;
  float: right;
  height: 100%;
  background-color: white;
  border: 1px solid grey;
  box-sizing: border-box;
}
.centerView {
  width: 90%;
  float: left;
  height: 100%;
  background-image: url("~@/assets/background/owood1.jpg");
  background-size: cover;
  overflow-y:auto !important;
  /* margin-bottom:10px; */
}
.phrase1 {
  width: 80%;
  margin-left: 10%;
  /* background-color: rgb(221, 210, 178); */

  color: white;
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-size: 1.8em;
  margin-bottom:5%;
}

.centerView1{
  width:100%;
  height:100%;
}
.centerViewIn {
  animation: centerViewIn .5s forwards;
}
.centerViewOut {
  animation: centerViewOut .5s forwards;
}
@keyframes centerViewIn {
  from { opacity:0; }
  to { opacity:1; }
}
@keyframes centerViewOut {
  from { opacity:1; }  
  to { opacity:0; }
}
@media only screen and (max-width: 900px) {
  .background {
    height: v-bind(h2) !important;
    min-height:initial !important;
  }
  .centerView {
    overscroll-behavior:contain;
  }
  .centerView1 {
    height: fit-content !important;
  }
  .navButtons {
    width:100%;
    margin:0;    
    padding:0;
    justify-content: space-evenly;
  }
  .fNav {
    height:5.95vh;
  }
  .navButton, .navButton1 {
    /* font-size:.8em; */
  }
  .navButton1 {
    /* display:flex; */
  }
  .ddButton {
    /* border:1px solid black;f */
    text-align:center;
    width:110%;
    height:1.5em;
    background-color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:12;
  }
  .ddBox {
    position:fixed;
    z-index:12;    
    background-color:white;    
  }
  .ddLine {
    width:90%;
    margin-left:5%;
    border:.5px solid black;

  }
  .backBtn{
    right:6vh;
  }
  .leftButton, .rightButton {
    padding-right:3%;
    padding-left:3%;
  }
  .exitButton {
    width:15vw;
    height:15vw;
    padding:0;
    margin:0;
    top:15%;
    display:flex;
    justify-content: center;
    align-items: center;
  }
}
</style>